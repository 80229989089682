<template>
  <div class="setting page with_side">
    <div class="side_box">
      <ul class="list">
        <router-link
            v-for="item in menu"
            :key="item.link"
            :to="'/settings/' + item.link"
            custom
            v-slot="{ href, navigate, isActive }">
          <li class="list_element" :class="[isActive && 'active']">
            <a class="list_link" :href="href" @click="navigate">
              {{ item.title }}
            </a>
          </li>
        </router-link>
      </ul>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "Settings",
  data() {
    return {
      menu: [
        {title: "Сотрудники", link: "employees"},
        {title: "Бригады", link: "brigades"},
        {title: "Юр.лица", link: "organizations"},
        {title: "Офисы", link: "offices"},
        {title: "Склады", link: "storages"},
        {title: "Статусы обращений", link: "statuses/request"},
        {title: "Статусы заказов", link: "statuses/order"},
        {title: "Поставщики", link: "suppliers"},
        {title: "Статьи расходов", link: "expenses"},
        {title: "Виды платежей", link: "payment_types"},
        {title: "Шаблоны документов", link: "document_templates"},
        {title: "Интеграции", link: "integrations"},
      ],
    }
  },
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  }
}
</script>
